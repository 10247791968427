import React, { useEffect, useRef, useState } from 'react';
import Countdown from 'react-countdown';
import monogram from './media/NSmonogram.png';
import venueLogo from './media/venueLogo.png';
import accommodationPDF from './media/accommodationOptions.pdf';
import bankingDetailsPDF from './media/bankingDetails.pdf';
import './App.css';

function App() {
  const [visibleIndex, setVisibleIndex] = useState(0);
  const [fade, setFade] = useState(true);
  const [language, setLanguage] = useState('');


  const paragraphs = [
    "Please select your language:",
    "Kies asseblief jou taal:",
    "Wählen Sie bitte Ihre Sprache:"
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false); // Start fade out
      setTimeout(() => {
        setVisibleIndex((prevIndex) => (prevIndex + 1) % paragraphs.length);
        setFade(true); // Start fade in
      }, 600); // 1 second fade out time
    }, 3500); // Total 3 seconds between switches (2s visible, 1s fade out)

    return () => clearInterval(interval);
  }, [paragraphs.length]);

  useEffect(() => {
    if (language) { // Only set the timer if a language has been selected
      const timer = setTimeout(() => {
        setScrollVisible(true);
      }, 10000); // 10 seconds

      return () => clearTimeout(timer);
    }
  }, [language]); // Depend on language state

  const videoRef = useRef(null);
  const inviteRef = useRef(null);
  const detailsRef = useRef(null);
  const rsvpRef = useRef(null);
  const furtherDetailsRef = useRef(null);
  const contactRef = useRef(null);
  const [scrollVisible, setScrollVisible] = useState(false);
  const [isInviteAtTop, setIsInviteAtTop] = useState(false);
  const [isDetailsAtTop, setIsDetailsAtTop] = useState(false);
  const [isFurtherDetailsAtTop, setIsFurtherDetailsAtTop] = useState(false);

  useEffect(() => {
    // Ensure the video plays automatically on load
    if (videoRef.current) {
      videoRef.current.muted = true;
      videoRef.current.play().catch((error) => {
        console.error('Autoplay failed:', error);
      });
    }

    // Set a timeout to make the "Scroll Down" text visible after 10 seconds
    const timer = setTimeout(() => {
      setScrollVisible(true);
    }, 10000);

    // Scroll event listener to check if sections have reached the top
    const handleScroll = () => {
      if (inviteRef.current) {
        const inviteTop = inviteRef.current.getBoundingClientRect().top;
        setIsInviteAtTop(inviteTop <= 0);
      }

      if (detailsRef.current) {
        const detailsTop = detailsRef.current.getBoundingClientRect().top;
        setIsDetailsAtTop(detailsTop <= 0);
      }

      if (furtherDetailsRef.current) {
        const furtherDetailsTop = furtherDetailsRef.current.getBoundingClientRect().top;
        setIsFurtherDetailsAtTop(furtherDetailsTop <= 0);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener and timer on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timer);
    };
  }, []);

  // Date for countdown
  const targetDate = new Date('2025-03-22T16:00:00');

  // Renderer function to customize the display
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a message if countdown is completed
      return <span>Time's up!</span>;
    } else if (language === 'English') {
      // Render the countdown
      return (
        <div className='countdown'>
          <div className='days-container countdown-item'>
            <span className='countdown-number'>{days}</span>
            <span className='countdown-unit'>days</span>
          </div>
          <div className='hours-container countdown-item'>
            <span className='countdown-number'>{hours}</span>
            <span className='countdown-unit'>hours</span>
          </div>
          <div className='minutes-container countdown-item'>
            <span className='countdown-number'>{minutes}</span>
            <span className='countdown-unit'>minutes</span>
          </div>
          <div className='seconds-container countdown-item'>
            <span className='countdown-number'>{seconds}</span>
            <span className='countdown-unit'>seconds</span>
          </div>
        </div>
      );
    } else if (language === 'Afrikaans') {
      return (
        <div className='countdown'>
          <div className='days-container countdown-item'>
            <span className='countdown-number'>{days}</span>
            <span className='countdown-unit'>dae</span>
          </div>
          <div className='hours-container countdown-item'>
            <span className='countdown-number'>{hours}</span>
            <span className='countdown-unit'>ure</span>
          </div>
          <div className='minutes-container countdown-item'>
            <span className='countdown-number'>{minutes}</span>
            <span className='countdown-unit'>minute</span>
          </div>
          <div className='seconds-container countdown-item'>
            <span className='countdown-number'>{seconds}</span>
            <span className='countdown-unit'>sekondes</span>
          </div>
        </div>
      );
    } else if (language === 'German') {
      return (
        <div className='countdown'>
          <div className='days-container countdown-item'>
            <span className='countdown-number'>{days}</span>
            <span className='countdown-unit'>tage</span>
          </div>
          <div className='hours-container countdown-item'>
            <span className='countdown-number'>{hours}</span>
            <span className='countdown-unit'>stunden</span>
          </div>
          <div className='minutes-container countdown-item'>
            <span className='countdown-number'>{minutes}</span>
            <span className='countdown-unit'>minuten</span>
          </div>
          <div className='seconds-container countdown-item'>
            <span className='countdown-number'>{seconds}</span>
            <span className='countdown-unit'>sekunden</span>
          </div>
        </div>
      );
    }
  };


  return (
    <div className='App'>

      <div className={`language-container ${language === '' ? '' : 'fade-out'}`}>
        <div className='language-screen'>
          {paragraphs.map((text, index) => (
            <p
              key={index}
              className={`fade-paragraph ${index === visibleIndex && fade ? 'visible fade-in' : 'fade-out'}`}
            >
              {text}
            </p>
          ))}
          <button className='language-button language-one' onClick={() => setLanguage('English')}>English</button>
          <button className='language-button language-two' onClick={() => setLanguage('Afrikaans')}>Afrikaans</button>
          <button className='language-button language-three' onClick={() => setLanguage('German')}>Deutsch</button>
        </div>
      </div>

      {language === 'English' && (
        <div className='english-content'>
          <div className="hero-section">
            <video
              src="https://github.com/Wed-ify/nina-and-seth/raw/main/static/media/mainVideo.bccbe79d3de73b9cbdbd.mp4"
              ref={videoRef}
              className="hero-video-mobile"
              autoPlay
              muted
              loop
              playsInline
            >
              Your browser does not support video playing.
            </video>

            <video
              src="https://github.com/Wed-ify/nina-and-seth/raw/main/static/media/mainVideoLarge274827382.mp4"
              ref={videoRef}
              className="hero-video-large"
              autoPlay
              muted
              loop
              playsInline
            >
              Your browser does not support video playing.
            </video>

            <div className="hero-content">
              <img src={monogram} alt='' className='monogram' />
              <div className='hero-bottom'>
                <h5>
                  Nina <span className='cursive'>and</span> Seth
                </h5>
                <Countdown date={targetDate} renderer={renderer} />
                <p className={`scrollDown ${scrollVisible ? 'visible' : ''}`}>Scroll Down</p>
              </div>
            </div>
          </div>

          <section className='invite-section' ref={inviteRef}>
            <div className='line'></div>
            <h5>
              Nina <br />
              van Rensburg
            </h5>
            <h5 className='cursive'>and</h5>
            <h5>
              Seth <br />
              Botes
            </h5>
            <p>Joyfully invite you to the celebration of their marriage</p>
            <h4>22 . 3 . 25</h4>
            <p>Paarl, Western Cape, South Africa</p>
            <h5 className='cursive'>reception to follow</h5>
            <div className='line'></div>
            <a href='https://maps.app.goo.gl/nrBhKpEMqsZFzBvk6' className='directionsButton'>Directions</a>
          </section>

          <div className={`venue-section ${isInviteAtTop ? 'at-top' : ''}`}>
            <video
              src='https://github.com/Wed-ify/nina-and-seth/raw/main/static/media/venueVideoMobile.42afbf5dd3e0164a34d9.mp4'
              ref={videoRef}
              className="venue-video-mobile"
              autoPlay
              muted
              loop
              playsInline
            >
              Your browser does not support video playing.
            </video>
            <video
              src='https://github.com/Wed-ify/nina-and-seth/raw/main/static/media/VenueVideoDesktop.mp4'
              ref={videoRef}
              className="venue-video-larger"
              autoPlay
              muted
              loop
              playsInline
            >
              Your browser does not support video playing.
            </video>
            <div className="venue-content">
              <div className='venue-outline'>
                <img src={venueLogo} alt='' />
                <p>Nestled in the heart of the Western Cape, Olijvenkraal offers a truly enchanting setting for our special day.</p>
                <p>Surrounded by majestic mountain ranges and sprawling olive groves, this rustic yet elegant venue embodies the essence of countryside charm. With its blend of natural beauty and modern amenities, Olijvenkraal provides an unforgettable backdrop for the start of our shared journey together.</p>
                <a href='#map' className='buttonLink'>Directions</a>
              </div>
            </div>
          </div>
          <section className='details-section' ref={detailsRef}>
            <h3>
              <span className='cursive'>the</span>
              <br />Details
            </h3>
            <h6>Ceremony</h6>
            <p>We invite you to witness our union as we exchange vows. The ceremony will begin promptly at Four o'clock in the afternoon. Please arrive from thirty minutes prior as we gather with our closest family and friends to celebrate our special moment.</p>
            <h6>Reception</h6>
            <p>Join us for an evening of celebration. Cocktails will be served from half past four in the afternoon with a formal dinner to follow at seven o'clock in the evening.</p>
            <h6>Accommodation</h6>
            <p>the venue has kindly provided a list of recommended options nearby. We encourage you to explore these choices and book early to secure your stay.</p>
            <a href={accommodationPDF}>View List</a>
            <a href='https://docs.google.com/forms/d/e/1FAIpQLSeB_8J4t8kwrV0_ZTSQkfTo5P_ThSuhe8Q4s6P5TPXTYuWNgg/viewform?usp=sf_link' className='rsvpButton'>RSVP here</a>
          </section>
          <div className={`rsvp-section ${isDetailsAtTop ? 'at-top' : ''}`} ref={rsvpRef}>
            <div className='rsvp-outline'>
              <h3>
                <span className='cursive'>kindly</span>
                <br />RSVP
              </h3>
              <div>
                <p>Please let us know, <strong>by the first of December</strong>, if you will be joining us. We look forward to celebrating with you!</p>
                <a href='#rsvp'>RSVP here</a>
              </div>
              <h5>
                Please Find <br />
                <span className='cursive'>further details below</span>
              </h5>
            </div>
          </div>
          <section className='further-details' ref={furtherDetailsRef}>
            <h3>
              <span className='cursive'>the</span>
              <br />Attire
            </h3>
            <h6>Dress Code</h6>
            <p>We kindly request that guests adhere to a <strong>black tie preferred</strong> dress code, with gentlemen in tuxedos or a formal suit and ladies in elegant floor length gowns.</p>
            <h6>Colour Scheme</h6>
            <p>Our wedding will feature a Classic and sophisticated Colour Scheme. Gentlemen are requested to wear a black tuxedo or a black formal suit with a black or white dress shirt. For the ladies, we suggest dark jewel tones, black, gold, or brown to complement the evening's elegant atmosphere.</p>
            <a href='https://pin.it/48nmi8TTr'>View Pinterest Board</a>
            <h3>
              <span className='cursive'>your</span>
              <br />Gifts
            </h3>
            <p>Your presence at our wedding is the greatest gift we could ask for. However, if you wish to honour us with a gift, we kindly request cash contributions. As we're unable to travel back with many physical items, your generosity will help us create lasting memories and settle into our new life together.</p>
            <a href={bankingDetailsPDF}>View Banking Details</a>
            <a href='mailto:botesvrens@gmail.com' className='contactButton'>Directions</a>
            <h3>
              <span className='cursive'>frequently asked</span>
              <br />questions
            </h3>
            <h6>May I bring a plus one?</h6>
            <p>
              Only guests who are explicitly invited by name are able to attend. To ensure an accurate guest list and a smooth planning process, each invited guest must RSVP using their own unique link that was sent to them.
            </p>
            <h6>Are children allowed?</h6>
            <p>
              While we love your little ones, this celebration is an <strong>adults-only</strong> occasion. We kindly ask that you make arrangements for your children, as we are unable to accommodate them at the venue.
            </p>
            <h6>Can I take photos during the ceremony?</h6>
            <p>
              We kindly ask that you refrain from taking any photos during the ceremony. <strong>Under no circumstances will photos be allowed</strong>, as we have professional photographers capturing these special moments. However, you are more than welcome to take photos during the cocktail hour, reception, and other events following the ceremony.
            </p>
          </section>
          <div className={`contact-section ${isFurtherDetailsAtTop ? 'at-top' : ''}`} ref={contactRef}>
            <div className='contact-outline'>
              <h3>
                <span className='cursive'>contact</span>
                <br />Us
              </h3>
              <p>For any questions or additional information, please don't hesitate to reach out to us.</p>
              <a href='#contact'>Send Email</a>
            </div>
          </div>
        </div>
      )}

      {language === 'Afrikaans' && (
        <div className='afrikaans-content'>
          <div className="hero-section">
            <video
              src="https://github.com/Wed-ify/nina-and-seth/raw/main/static/media/mainVideo.bccbe79d3de73b9cbdbd.mp4"
              ref={videoRef}
              className="hero-video-mobile"
              autoPlay
              muted
              loop
              playsInline
            >
              Your browser does not support video playing.
            </video>

            <video
              src="https://github.com/Wed-ify/nina-and-seth/raw/main/static/media/mainVideoLarge274827382.mp4"
              ref={videoRef}
              className="hero-video-large"
              autoPlay
              muted
              loop
              playsInline
            >
              Your browser does not support video playing.
            </video>
            <div className="hero-content">
              <img src={monogram} alt='' className='monogram' />
              <div className='hero-bottom'>
                <h5>
                  Nina <span className='cursive'>en</span> Seth
                </h5>
                <Countdown date={targetDate} renderer={renderer} />
                <p className={`scrollDown ${scrollVisible ? 'visible' : ''}`}>Blaai Af</p>
              </div>
            </div>
          </div>

          <section className='invite-section' ref={inviteRef}>
            <div className='line'></div>
            <h5>
              Nina <br />
              van Rensburg
            </h5>
            <h5 className='cursive'>en</h5>
            <h5>
              Seth <br />
              Botes
            </h5>
            <p>Nooi jou vreugdelik uit na die bevestiging van hul huwelik</p>
            <h4>22 . 3 . 25</h4>
            <p>Paarl, Weskaap, Suid-Afrika</p>
            <h5 className='cursive'>onthaal sal volg daarna</h5>
            <div className='line'></div>
            <a href='https://maps.app.goo.gl/nrBhKpEMqsZFzBvk6' className='directionsButton'>Directions</a>
          </section>

          <div className={`venue-section ${isInviteAtTop ? 'at-top' : ''}`}>
            <video
              src='https://github.com/Wed-ify/nina-and-seth/raw/main/static/media/venueVideoMobile.42afbf5dd3e0164a34d9.mp4'
              ref={videoRef}
              className="venue-video-mobile"
              autoPlay
              muted
              loop
              playsInline
            >
              Your browser does not support video playing.
            </video>
            <video
              src='https://github.com/Wed-ify/nina-and-seth/raw/main/static/media/VenueVideoDesktop.mp4'
              ref={videoRef}
              className="venue-video-larger"
              autoPlay
              muted
              loop
              playsInline
            >
              Your browser does not support video playing.
            </video>
            <div className="venue-content">
              <div className='venue-outline'>
                <img src={venueLogo} alt='' />
                <p>Genestel in die hart van die Wes-Kaap, bied Olijvenkraal 'n werklik betowerende omgewing vir ons spesiale dag.</p>
                <p>Omring deur asemrowende bergreekse en uitgestrekte olyfboorde, inkarneer hierdie rustieke maar elegante plek die essensie van plattelandse sjarme. Met 'n mengsel van natuurlike skoonheid en moderne geriewe, bied Olijvenkraal 'n onvergeetlike agtergrond vir die begin van ons gesamentlike lewensreis.</p>
                <a href='#map' className='buttonLink'>Padwysings</a>
              </div>
            </div>
          </div>
          <section className='details-section' ref={detailsRef}>
            <h3>
              <span className='cursive'>die</span>
              <br /><span className='smaller'>Besonderhede</span>
            </h3>
            <h6>Seremonie</h6>
            <p>Ons nooi jou uit om getuie te wees gedurende ons verbintenis in die huwelik terwyl ons ons geloftes uitruil. Die seremonie sal om stiptelik vier uur smiddags begin. Ons vra dat ons gaste vanaf dertig minute vroeër sal aansit soos ons saam met ons naaste familie en vriende vergader om ons spesiale oomblik te vier.</p>
            <h6>Onthaal</h6>
            <p>Sluit by ons aan vir 'n aand van feesvieringe. Skemerkelkies sal vanaf half vyf smiddags bedien word en 'n formele aandete sal om sewe uur saans volg.</p>
            <h6>Akkommodasie</h6>
            <p>Die venue het 'n lys van aanbevole opsies in die omgewing verskaf. Ons moedig jou aan om hierdie keuses te oorweeg en vroegtydig te bespreek om jou verblyf te verseker.</p>
            <a href={accommodationPDF}>Bekyk Lys</a>
            <a href='https://docs.google.com/forms/d/e/1FAIpQLSeB_8J4t8kwrV0_ZTSQkfTo5P_ThSuhe8Q4s6P5TPXTYuWNgg/viewform?usp=sf_link' className='rsvpButton'>RSVP here</a>
          </section>
          <div className={`rsvp-section ${isDetailsAtTop ? 'at-top' : ''}`} ref={rsvpRef}>
            <div className='rsvp-outline'>
              <h3>
                <span className='slightDown'>RSVP <br /></span>
                <span className='cursive'>asseblief</span>
              </h3>
              <div>
                <p>Laat ons asseblief weet, <strong>teen die eerste desember</strong>, of jy by ons sal aansluit. Ons sien uit daarna om saam met jou te vier!</p>
                <a href='#rsvp'>RSVP hier</a>
              </div>
              <h5>
                Vind Asseblief <br />
                <span className='cursive'>verdere besonderhede</span>
              </h5>
            </div>
          </div>
          <section className='further-details' ref={furtherDetailsRef}>
            <h3>
              <span className='cursive'>die</span>
              <br />Drag
            </h3>
            <h6>Kleredrag</h6>
            <p>Ons versoek vriendelik dat gaste die kleredrag van <em><strong>black tie preffered</strong></em> nakom, met mans in tuxedos of 'n formele pak en dames in elegante vloer-lengte rokke.</p>
            <h6>Kleurskema</h6>
            <p>Ons troue sal 'n klassieke en gesofistikeerde kleurskema hê. Mans word versoek om 'n swart tuxedo of 'n swart formele pak met 'n swart of wit knoophemp te dra. Vir die dames stel ons donker edelsteen kleure, swart, goud, of bruin voor om die aand se elegante atmosfeer te komplimenteer.</p>
            <a href='https://pin.it/48nmi8TTr'>Sien 'Pinterest Board'</a>
            <h3>
              <span className='cursive'>julle</span>
              <br />Geskenke
            </h3>
            <p>Jou teenwoordigheid by ons troue is die grootste geskenk waarvoor ons kan vra. Indien jy egter graag iets vir ons wil skenk, versoek ons vriendelik kontantbydraes. Aangesien ons nie in staat sal wees om baie fisiese items terug te neem nie, sal jou vrygewigheid ons help om blywende herinneringe te skep en om saam ons nuwe lewe te vestig.</p>
            <a href={bankingDetailsPDF}>Sien Bankingbesonderhede</a>
            <h3>
              <span className='cursive'>gereelde</span>
              <br />vrae
            </h3>
            <h6>Mag ek 'n metgesel saambring?</h6>
            <p>Slegs gaste wat uitdruklik per naam uitgenooi is, mag die geleentheid bywoon. Om 'n akkurate gastelys te verseker, moet elke gas RSVP met hul eie unieke skakel wat aan hulle gestuur is.</p>
            <h6>Is kinders toegelaat by die troue?</h6>
            <p>Hoewel ons baie lief is vir jou kleingoed, is hierdie viering slegs vir volwassenes. Ons vra vriendelik dat jy reëlings vir jou kinders tref, aangesien ons hulle ongelukkig nie kan akkommodeer nie.</p>
            <h6>Mag ek tydens die seremonie foto's neem?</h6>
            <p>Ons vra vriendelik dat daar nie foto's tydens die seremonie geneem word nie.<strong> Onder geen omstandighede word foto's toegelaat nie</strong>, aangesien ons professionele fotograwe het wat hierdie spesiale oomblikke sal afneem. Jy is egter welkom om foto's tydens die skemerkelkies, onthaal en ander geleenthede na die seremonie te neem. </p>
            <a href='mailto:botesvrens@gmail.com' className='contactButton'>Directions</a>
          </section>
          <div className={`contact-section ${isFurtherDetailsAtTop ? 'at-top' : ''}`} ref={contactRef}>
            <div className='contact-outline'>
              <h3>
                <span className='cursive'>kontak</span>
                <br />ons
              </h3>
              <p>Vir enige vrae of addisionele inligting, moenie huiwer om ons te kontak nie.</p>
              <a href='#contact'>Stuur E-pos</a>
            </div>
          </div>
        </div>
      )}

      {language === 'German' && (
        <div className='english-content'>
          <div className="hero-section">
            <video
              src="https://github.com/Wed-ify/nina-and-seth/raw/main/static/media/mainVideo.bccbe79d3de73b9cbdbd.mp4"
              ref={videoRef}
              className="hero-video-mobile"
              autoPlay
              muted
              loop
              playsInline
            >
              Your browser does not support video playing.
            </video>

            <video
              src="https://github.com/Wed-ify/nina-and-seth/raw/main/static/media/mainVideoLarge274827382.mp4"
              ref={videoRef}
              className="hero-video-large"
              autoPlay
              muted
              loop
              playsInline
            >
              Your browser does not support video playing.
            </video>
            <div className="hero-content">
              <img src={monogram} alt='' className='monogram' />
              <div className='hero-bottom'>
                <h5>
                  Nina <span className='cursive'>und</span> Seth
                </h5>
                <Countdown date={targetDate} renderer={renderer} />
                <p className={`scrollDown ${scrollVisible ? 'visible' : ''}`}>Wisch nach Unten</p>
              </div>
            </div>
          </div>

          <section className='invite-section' ref={inviteRef}>
            <div className='line'></div>
            <h5>
              Nina <br />
              van Rensburg
            </h5>
            <h5 className='cursive'>und</h5>
            <h5>
              Seth <br />
              Botes
            </h5>
            <p>Laden Sie herzlich zur Feier ihrer Hochzeit ein.</p>
            <h4>22 . 3 . 25</h4>
            <p>Paarl, Westkap, Südafrika</p>
            <h5 className='cursive'>Empfang volgt danach</h5>
            <div className='line'></div>
            <a href='https://maps.app.goo.gl/nrBhKpEMqsZFzBvk6' className='directionsButton'>Directions</a>
          </section>

          <div className={`venue-section ${isInviteAtTop ? 'at-top' : ''}`}>
            <video
              src='https://github.com/Wed-ify/nina-and-seth/raw/main/static/media/venueVideoMobile.42afbf5dd3e0164a34d9.mp4'
              ref={videoRef}
              className="venue-video-mobile"
              autoPlay
              muted
              loop
              playsInline
            >
              Your browser does not support video playing.
            </video>
            <video
              src='https://github.com/Wed-ify/nina-and-seth/raw/main/static/media/VenueVideoDesktop.mp4'
              ref={videoRef}
              className="venue-video-larger"
              autoPlay
              muted
              loop
              playsInline
            >
              Your browser does not support video playing.
            </video>
            <div className="venue-content">
              <div className='venue-outline'>
                <img src={venueLogo} alt='' />
                <p>Gelegen im Herzen des Westkap bietet Olijvenkraal eine wirklich bezaubernde Kulisse für unseren besonderen Tag.</p>
                <p>Umgeben von majestätischen Gebirgsketten und ausgedehnten Olivenhainen verkörpert dieser rustikale doch elegante Veranstaltungsort die Essenz des ländlichen Charmes. Mit seiner Mischung aus natürlicher Schönheit und modernen Annehmlichkeiten bietet Olijvenkraal eine unvergessliche Kulisse für den Beginn unserer gemeinsamen Reise durchs Leben.</p>
                <a href='#map' className='buttonLink'>Kartenanweisungen</a>
              </div>
            </div>
          </div>
          <section className='details-section' ref={detailsRef}>
            <h3>
              <span className='cursive'>die</span>
              <br /><span className='smaller'>Einzelheiten</span>
            </h3>
            <h6>Zeremonie</h6>
            <p>Wir laden Sie ein, unsere Vereinigung zu bezeugen, während wir unsere Gelübde austauschen. Die Zeremonie beginnt pünktlich um vier Uhr. Bitte nehmen Sie ab dreizig Minuten vorher Platz, damit wir uns mit unserer engsten Familie und unseren Freunden versammeln, um unseren besonderen Moment zu feiern.</p>
            <h6>Empfang</h6>
            <p>Feiern Sie mit uns einen Abend voller Festlichkeiten. AB HALB FÜNF UHR NACHMITTAGS WERDEN COCKTAILS SERVIERT, GEFOLGT VON EINEM FORMELLEN ABENDESSEN UM SIEBEN UHR ABENDS.</p>
            <h6>Unterkunft</h6>
            <p>Der Hochzeitsort hat freundlicherweise eine Liste von empfohlenen Optionen in der Nähe bereitgestellt. Wir empfehlen Ihnen, diese Auswahl zu erkunden und frühzeitig zu buchen, um Ihren Aufenthalt zu sichern.</p>
            <a href={accommodationPDF}>Liste besichtigen</a>
            <a href='https://docs.google.com/forms/d/e/1FAIpQLSeB_8J4t8kwrV0_ZTSQkfTo5P_ThSuhe8Q4s6P5TPXTYuWNgg/viewform?usp=sf_link' className='rsvpButton'>RSVP here</a>
          </section>
          <div className={`rsvp-section ${isDetailsAtTop ? 'at-top' : ''}`} ref={rsvpRef}>
            <div className='rsvp-outline'>
              <h3>
                <span className='cursive'>bitte um</span>
                <br /><span className='smaller'>Rückmeldung</span>
              </h3>
              <div>
                <p>Bitte teilen Sie uns <strong>bis zum ersten Dezember mit</strong>, ob Sie an unserer Hochzeitsfeier teilnehmen werden. Wir freuen uns darauf, mit Ihnen zu feiern!</p>
                <a href='#rsvp'>Zur RSVP</a>
              </div>
              <h5 className='information-heading-german'>
                Weitere <br /> informationen <br />
                <span className='cursive'>nach unten</span>
              </h5>
            </div>
          </div>
          <section className='further-details' ref={furtherDetailsRef}>
            <h3>
              <span className='cursive'>die</span>
              <br />Tracht
            </h3>
            <h6>Kleiderordnung</h6>
            <p>WIR BITTEN UNSERE GÄSTE, SICH AN DIE KLEIDERORDNUNG „SCHWARZE KRAWATTE BEVORZUGT“ ZU HALTEN, WOBEI DIE HERREN EINEN SMOKING ODER EINEN FORMELLEN ANZUG UND DIE DAMEN EIN ELEGANTES BODENLANGES KLEID TRAGEN SOLLTEN.</p>
            <h6>Farbschema</h6>
            <p>UNSERE HOCHZEIT WIRD EIN KLASSISCHES UND ANSPRUCHSVOLLES FARBSCHEMA HABEN. DIE HERREN WERDEN GEBETEN, EINEN SCHWARZEN SMOKING ODER EINEN SCHWARZEN FORMELLEN ANZUG MIT EINEM SCHWARZEN ODER WEISSEN HEMD ZU TRAGEN. FÜR DIE DAMEN EMPFEHLEN WIR DUNKLE EDELSTEINFARBEN, SCHWARTZ, GOLD ODER BRAUN, UM DIE ELEGANTE ATMOSPHÄRE DES ABENDS ZU ERGÄNZEN.</p>
            <a href='https://pin.it/48nmi8TTr'>,Pinterest Board' anschauen</a>
            <h3>
              <span className='cursive'>ihre</span>
              <br />Geschenke
            </h3>
            <p>Ihre Anwesenheit bei unserer Hochzeit ist das größte Geschenk, das wir uns wünschen könnten. Wenn Sie uns jedoch gerne mit einem Geschenk ehren möchten, bitten wir freundlich um Geldspenden. Da wir nicht in der Lage sein werden, viele physische Geschenke zurückzunehmen, wird Ihre Großzügigkeit uns helfen, bleibende Erinnerungen zu schaffen und uns in unser neues Leben zusammen einzuleben.</p>
            <a href={bankingDetailsPDF}>Sehe Bankverbindung</a>
            <h3>
              <span className='cursive'>Wichtige</span>
              <br />Fragen
            </h3>
            <h6>Darf ich jemanden mitbringen?</h6>
            <p>Nur Gäste, die namentlich eingeladen wurden, dürfen teilnehmen. Um eine genaue Gästeliste zu gewährleisten, muss jeder eingeladene Gast mit dem einzigartigen Link, der an sie gesendet wurde, antworten.</p>
            <h6>Sind Kinder bei der Hochzeit erlaubt?</h6>
            <p>Obwohl wir Ihre Kleinen lieben, ist diese Veranstaltung nur für Erwachsene. Wir bitten Sie freundlich, für Ihre Kinder entsprechende Vorkehrungen zu treffen, da wir sie leider nicht unterbringen können.</p>
            <h6>Darf ich während der Zeremonie Fotos machen?</h6>
            <p>Wir bitten Sie höflich, während der Zeremonie keine Fotos zu machen. <strong>Unter keinen Umständen sind Fotos erlaubt</strong>, da wir professionelle Fotografen haben, die diese besonderen Momente fotografieren werden. Nach der Zeremonie, während der Cocktailstunde, beim Empfang und bei anderen Veranstaltungen sind Sie jedoch herzlich eingeladen, Fotos zu machen. Vielen Dank für Ihr Verständnis!</p>
            <a href='mailto:botesvrens@gmail.com' className='contactButton'>Directions</a>
          </section>
          <div className={`contact-section ${isFurtherDetailsAtTop ? 'at-top' : ''}`} ref={contactRef}>
            <div className='contact-outline'>
              <h3>
                <span className='cursive'>kontaktiere</span>
                <br />uns
              </h3>
              <p>Bei Fragen oder für zusätzliche Informationen zögern Sie bitte nicht, uns zu kontaktieren.</p>
              <a href='#contact'>E-mail senden</a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
